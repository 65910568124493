import jwtDecode from 'jwt-decode';
import moment from 'moment';

export const ifAuthenticated = (to, from, next) => {
  const token = JSON.parse(localStorage.getItem('vuex') || '{}').user?.token;

  if (token) {
    const decoded = jwtDecode(token);

    if (decoded.exp >= moment().format('X')) {
      next();
    } else {
      window.location.href = '/?expired=true';
    }
  } else {
    window.location.href = '/?expired=true';
  }
};

export const ifIsAdmin = (to, from, next) => {
  const token = JSON.parse(localStorage.getItem('vuex') || '{}').user?.token;

  if (token) {
    const decoded = jwtDecode(token);

    if (decoded.roles.some((role) => role === 'master_admin')) {
      next();
    } else {
      window.location.href = '/?expired=true';
    }
  } else {
    window.location.href = '/?expired=true';
  }
};

export const isAdmin = () => {
  const token = JSON.parse(localStorage.getItem('vuex') || '{}').user?.token;

  if (token) {
    const decoded = jwtDecode(token);

    if (decoded.roles.some((role) => role === 'master_admin')) {
      return true;
    }

    return false;
  }

  return false;
};
