import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import pt_br from 'element-ui/lib/locale/lang/pt-br';
import locale from 'element-ui/lib/locale';
import VueMask from 'v-mask';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';
import 'regenerator-runtime/runtime';
import moment from 'moment';
// import { createProvider } from "./vue-apollo";

Vue.config.productionTip = false;
moment.locale('pt-br');
Vue.prototype.moment = moment;
Vue.use(ElementUI);
locale.use(pt_br);
Vue.use(VueMask);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  router,
  store,
  i18n,
  // apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
