<template>
  <div
    id="app"
    :style="`padding-top: ${this.insets.top}px; padding-left: ${this.insets.left}px; padding-right: ${this.insets.right}px; padding-bottom: ${this.insets.bottom}px;`"
  >
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { SafeArea } from "capacitor-plugin-safe-area";

export default {
  data() {
    return {
      insets: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    };
  },
  beforeMount() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      this.insets = insets;
    });
    window.addEventListener("resize", (event) => {
      this.handleWindowResize(event);
    });
  },
  methods: {
    ...mapActions(["setInnerHeight", "setInnerWidth"]),
    handleWindowResize(event) {
      const { target } = event;
      const { innerHeight, innerWidth } = target;

      this.setInnerHeight(innerHeight);
      this.setInnerWidth(innerWidth);
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 0px;
  -webkit-text-size-adjust: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc;
}
</style>
