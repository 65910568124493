import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {},
    selectedCompany: '',
    vehicles: [],
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getCompanies(state) {
      return state.user.companies;
    },
    getSelectedCompany(state) {
      return state.selectedCompany;
    },
    getVehicles(state) {
      return state.vehicles;
    },
    getInnerHeight(state) {
      return state.innerHeight;
    },
    getInnerWidth(state) {
      return state.innerWidth;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setSelectedCompany(state, payload) {
      state.selectedCompany = payload;
    },
    setVehicles(state, payload) {
      state.vehicles = payload;
    },
    logout(state) {
      state.user = {};
      state.selectedCompany = {};
      state.vehicles = [];
    },
    setInnerHeight(state, payload) {
      state.innerHeight = payload;
    },
    setInnerWidth(state, payload) {
      state.innerWidth = payload;
    },
  },
  actions: {
    setUser({ commit }, payload) {
      commit('setUser', payload);
    },
    setSelectedCompany({ commit }, payload) {
      commit('setSelectedCompany', payload);
    },
    setVehicles({ commit }, payload) {
      commit('setVehicles', payload);
    },
    logout({ commit }, payload) {
      commit('logout', payload);
    },
    setInnerHeight({ commit }, payload) {
      commit('setInnerHeight', payload);
    },
    setInnerWidth({ commit }, payload) {
      commit('setInnerWidth', payload);
    },
  },
  modules: {},
});
