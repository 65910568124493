import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '@/i18n';
import { ifAuthenticated, ifIsAdmin } from '../helpers/token';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/LoginView'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/DashboardView'),
    meta: { title: 'Dashboard' },
    redirect: { name: 'Map' },
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'admin/companies',
        name: 'Admin Companies',
        component: () => import('../views/Dashboard/Admin/CompaniesAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Companies' },
      },
      {
        path: 'manage-company/:id?',
        name: 'Manage Company',
        component: () =>
          import('../views/Dashboard/Admin/ManageCompaniesAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Companies' },
      },
      {
        path: 'admin/users',
        name: 'Admin Users',
        component: () => import('../views/Dashboard/Admin/UsersAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Users' },
      },
      {
        path: 'manage-user/:id?',
        name: 'Manage User',
        component: () => import('../views/Dashboard/Admin/ManageUsersAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Users' },
      },
      {
        path: 'admin/trackers',
        name: 'Admin Trackers',
        component: () => import('../views/Dashboard/Admin/TrackersAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Trackers' },
      },
      {
        path: 'manage-tracker/:id?',
        name: 'Manage Tracker',
        component: () => import('../views/Dashboard/Admin/ManageTrackersAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Trackers' },
      },
      {
        path: 'admin/vehicles',
        name: 'Admin Vehicles',
        component: () => import('../views/Dashboard/Admin/VehiclesAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Vehicles' },
      },
      {
        path: 'manage-vehicle/:id?',
        name: 'Manage Vehicle',
        component: () => import('../views/Dashboard/Admin/ManageVehiclesAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Vehicles' },
      },
      {
        path: 'admin/drivers',
        name: 'Admin Drivers',
        component: () => import('../views/Dashboard/Admin/DriversAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Drivers' },
      },
      {
        path: 'manage-driver/:id?',
        name: 'Manage Driver',
        component: () => import('../views/Dashboard/Admin/ManageDriversAdmin'),
        beforeEnter: ifIsAdmin,
        meta: { title: 'Drivers' },
      },
      // Users Views
      {
        path: 'vehicles',
        name: 'Company Vehicles',
        component: () => import('../views/Dashboard/User/VehiclesUser'),
        meta: { title: 'Vehicles' },
      },
      {
        path: 'drivers',
        name: 'Company Drivers',
        component: () => import('../views/Dashboard/User/DriversUser'),
        meta: { title: 'Drivers' },
      },
      {
        path: 'map',
        name: 'Map',
        component: () => import('../views/Pages/MapView.vue'),
        meta: { title: 'Map' },
      },
      {
        path: 'history/:id',
        name: 'History',
        component: () => import('../views/Pages/MapHistoryView.vue'),
        meta: { title: 'Map' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const title = i18n.t(`dashboard.menu.${to.meta?.title?.toLowerCase()}`);
  document.title = `Fullsat - ${title}`;
  next();
});

export default router;
